import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import Content from './Content'
import {HTMLContent} from './Content'
import {remark} from 'remark'
import remarkHtml from 'remark-html'

const BoardPageTemplate = ({ title, notice, board, content, disclosure, activeConflicts, contentComponent}) => {
  const PageContent = contentComponent || Content

  function Notice({notice}) {
    if(notice.title !== null) {
      return (
        <article className="message">
          <div className="message-header">
            {notice.title}
          </div>
          <div className="message-body">
            <p>{notice.message}</p>
          </div>
        </article>
      )
    }
    else {
      return (
        < ></ >
      )
    }
  }

  return (
    <section className="section section--gradient">
      <div className="content-spacer"></div>
      <div className="content container">
        <div className="columns">
          <div className="column is-8 is-offset-1">
            <div className="section">
              <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
                {title}
              </h1>

              <Notice notice={notice} />
              <PageContent className="content" content={content} />

              <hr />
              {board.map(staff => (
                <div key={staff.name}>
                    <p  className="is-size-4">{staff.name} <br /><small className="has-text-grey">{staff.role}</small><br />
                    <span className="has-text-grey" style={{ fontSize: '0.8rem' }}> Term: {staff.term}</span></p>
                    <p className="has-text-grey">Business Phone:	(713) 860-6400<br />Fax:	(713) 860-6401<br />
                    Mailing:	3200 Southwest Fwy., Ste. 2600, Houston, TX 77027</p>
                  <hr />
                </div>
              ))}

              <h3 className="is-size-3">Conflicts Disclosure Statements:</h3>
              {(activeConflicts) 
              ? <p><a href={disclosure.publicURL} className="button">View disclosure statement(s)</a></p> 
              : <p>No conflicts to report for the current Board of Directors.</p> }

              {/* <Img fluid={groupPhoto.childImageSharp.fluid} alt="" /> */}

            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

BoardPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

export default BoardPageTemplate
